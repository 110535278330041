import axios from 'axios'
import router from '../../router'

const state = () => ({
    authInfo: null
})

// getters
const getters = {

}

// actions
const actions = {
    loadAuthInfo({ commit }) {
        axios.get("/auth/info").then((resp) => {
            if (resp.data.success) {
                commit('setAuthInfo', resp.data.data)
            } else {
                alert(resp.data.msg);
            }
        });
    },

    login({commit}, loginInfo) {
        axios.post("/auth/login", loginInfo).then((resp) => {
            if (resp.data.success) {
                router.push("/info");
            } else {
                alert(resp.data.msg);
            }
        });
    },

    logout({ commit }) {
        axios.post("/auth/logout").then((resp) => {
            if (resp.data.success) {
                commit('setAuthInfo', null)
                router.push("/");
            } else {
                alert(resp.data.msg);
            }
        });
    },

    checkLogin({ commit }) {
        axios.post("/auth/check").then((resp) => {
            if (resp.data.success) {
                commit('setAuthInfo', null)
                router.push("/");
            } else {
                alert(resp.data.msg);
            }
        });
    },
}

// mutations
const mutations = {
    setAuthInfo(state, authInfo) {
        state.authInfo = authInfo;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}