const routes = [
    {
        path: '/',
        inNav: false,
        name: '直播管理系统',
        component: () => import('../views/auth/Login.vue'),
    },
    {
        path: '/auth',
        inNav: false,
        name: '登陆信息',
        component: () => import('../views/Main.vue'),
        children: [
            {
                path: '/info',
                name: '',
                component: () => import('../views/auth/Info.vue')
            },
        ],

    },
    {
        path: '/sys',
        name: '系统管理',
        inNav: true,
        permissions: ['SYS'],
        component: () => import('../views/Main.vue'),
        children: [
            {
                path: '/su-list',
                name: '系统用户',
                component: () => import('../views/sys/AdminUserList.vue')
            },
        ]
    },
    {
        path: '/studio',
        name: '直播间管理',
        inNav: true,
        permissions: ['STUDIO'],
        component: () => import('../views/Main.vue'),
        children: [
            {
                path: '/studio-list',
                name: '列表',
                component: () => import('../views/studio/StudioList.vue')
            },
        ]
    },
    {
        path: '/anchor',
        name: '主播管理',
        inNav: true,
        permissions: ['ANCHOR'],
        component: () => import('../views/Main.vue'),
        children: [
            {
                path: '/anchor-create',
                name: '录入',
                auTypes: ['ROOT','AGENT'],
                component: () => import('../views/anchor/AnchorCreate.vue')
            },
            {
                path: '/anchor-list',
                name: '列表',
                component: () => import('../views/anchor/AnchorList.vue')
            },
            {
                path: '/anchor-stat',
                name: '统计',
                auTypes: ['ROOT','AGENT'],
                component: () => import('../views/anchor/Stat.vue')
            },
            
        ]
    },
]


export default routes